import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import homeImage from "./home.webp";

const StyledImage = styled("img")(() => ({
  maxWidth: "100%",
}));

function Home() {
  return (
    <Box
      sx={{
        mx: "auto",
        my: 1,
        maxWidth: "90%",
        textAlign: "center",
        width: "900px",
      }}
    >
      <StyledImage src={homeImage} alt="The First Mistake" />
      <Typography variant="h4">My Story</Typography>
      <Typography>
        Cathy Anderson has been capturing a variety of subjects with photography
        and more recently using watercolor, pastels and acrylics. She has
        captured those images of the area she grew up in; South Dakota
        homesteads and small towns, with her first digital camera starting in
        2007. Cathy's passion for photography continued after moving from
        Wyoming to Washington. In Seattle Cathy studied painting with various
        artists of watercolor, pastels and acrylic painting. During the pandemic
        she took classes from Gage Academy, Winslow Art and Birgit O'Connor in
        abstract art, watercolor painting, and artistic techniques.
        <br />
        <br />
        Returning to Wyoming in 2021 and after getting settled in her Gillette
        home she continued to paint. Cathy is focusing on her love for the
        nature that surrounds her, the prairies, and using photographs that
        exist in her colletion of almost 20 years. All artwork is inspired by
        Cathy's photographs as she captures, with the paintbrush; homesteads,
        rural life, nature and abstract art.
      </Typography>
    </Box>
  );
}

export default Home;
