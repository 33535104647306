import { NavLink, NavLinkProps } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledNavLink = styled(NavLink)<NavLinkProps>(({ theme }) => ({
  textDecoration: "none",
  transition: theme.transitions.create(["color", "transform"]),
  "&:hover": {
    color: "unset",
  },
}));

export default StyledNavLink;
