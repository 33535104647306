import { useState } from "react";
import { Box, Button, Snackbar, styled, TextField } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../utils/firebase";

const textFields = [
  { name: "email", label: "E-Mail", multiline: false },
  { name: "subject", label: "Subject", multiline: false },
  { name: "text", label: "Message", multiline: true },
];

const initialState = {
  email: "",
  subject: "",
  text: "",
};

type AvailableFields = "email" | "subject" | "text";

const StyledForm = styled(TextField)(() => ({
  margin: 8,
}));

const StyledButton = styled(Button)(() => ({
  marginLeft: "auto",
}));

const Contact = () => {
  const [formData, setFormData] = useState(initialState);
  const [submitMessage, setSubmitMessage] = useState("");
  const disableSubmit = Object.values(formData).some((x) => !x);

  const handleChange = ({
    target: { value, name },
  }: {
    target: {
      value: string;
      name: string;
    };
  }) => {
    setFormData((currentForm) => ({
      ...currentForm,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { text, subject, ...details } = formData;
    await addDoc(collection(db, "ca-art-contact"), {
      ...details,
      to: "andersonlcathy@gmail.com",
      message: {
        subject,
        text,
      },
    })
      .then(() => {
        setFormData(initialState);
        setSubmitMessage(
          "We have received your request and will respond shortly!"
        );
      })
      .catch(() => {
        setSubmitMessage(
          "We were unable to process your request, please contact us directly using the links below!"
        );
      });
  };

  const snackbarOpen = !!submitMessage;

  const handleCloseSnackbar = () => setSubmitMessage("");

  return (
    <Box
      component="form"
      sx={{ mx: "auto", my: 2, width: "90%", maxWidth: "800px" }}
      display="flex"
      flexDirection="column"
    >
      {textFields.map(({ name, label, multiline }) => (
        <StyledForm
          id={name}
          key={name}
          label={label}
          multiline={multiline}
          name={name}
          onChange={handleChange}
          rows={3}
          value={formData[name as AvailableFields]}
          variant="filled"
        />
      ))}
      <StyledButton
        color="primary"
        disabled={disableSubmit}
        onClick={handleSubmit}
        variant="contained"
      >
        Submit
      </StyledButton>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        message={submitMessage}
        onClose={handleCloseSnackbar}
        open={snackbarOpen}
      />
    </Box>
  );
};

export default Contact;
