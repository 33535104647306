import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    error: {
      main: "#E3DOD8",
    },
    primary: {
      main: "#C6D2ED",
    },
    secondary: {
      main: "#827081",
    },
    success: {
      main: "#00796B",
    },
    warning: {
      main: "#FAA613",
    },
    background: {
      default: "#E7E6F7",
    },
  },
});
