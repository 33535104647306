import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBsfRtcaEZpQOa8Xi9qg5ORtWY7NCUeuPQ",
  authDomain: "the-green-owl-115d0.firebaseapp.com",
  projectId: "the-green-owl-115d0",
  storageBucket: "the-green-owl-115d0.appspot.com",
  messagingSenderId: "1010166498229",
  appId: "1:1010166498229:web:c52a2fb5319fcb7bb74ffc",
  measurementId: "G-V7NKXW03T8",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

if (location.hostname === "localhost") {
  connectFirestoreEmulator(db, "localhost", 8080);
}
