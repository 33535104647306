import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import StyledNavLink from "./StyledNavLink";

const HeaderWrapper = styled("header")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: "1em",
  textAlign: "center",
  "& a": {
    color: theme.palette.secondary.contrastText,
  },
  "& a.active": {
    color: "unset",
  },
  [theme.breakpoints.down("md")]: {
    "& h1": {
      fontSize: "2em",
    },
    "& h2": {
      display: "none",
    },
  },
}));

function Header() {
  return (
    <HeaderWrapper>
      <Typography variant="h1">Cathy Anderson</Typography>
      <Typography variant="h2">Art and Photography</Typography>
      <Box
        sx={{
          borderColor: "secondary.contrastText",
          borderTopStyle: "solid",
          borderTopWidth: "1px",
          display: "flex",
          justifyContent: "space-around",
          margin: "auto",
          maxWidth: "90%",
          width: "400px",
        }}
      >
        <StyledNavLink to="/">
          <Typography>Home</Typography>
        </StyledNavLink>
        <StyledNavLink to="/gallery">
          <Typography>Gallery</Typography>
        </StyledNavLink>
        <StyledNavLink to="/contact">
          <Typography>Contact</Typography>
        </StyledNavLink>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
