import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";

function Gallery() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    return () => setIsLoading(true);
  }, []);

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            position: "absolute",
            width: "100%",
            zIndex: 0,
          }}
        >
          <CircularProgress sx={{ mx: "auto", mt: 3 }} color="secondary" />
        </Box>
      )}
      <script
        type="text/javascript"
        src="https://fineartamerica.com/widgetshoppingcart/widgetscripts.php"
      />
      <iframe
        id="pixelsshoppingcartiframe"
        onLoad={() => setIsLoading(false)}
        src="https://fineartamerica.com/widgetshoppingcart/artwork.html?memberidtype=artistid&memberid=268749&domainid=0&showheader=0&height=600&autoheight=true"
        style={{
          border: "none",
          display: "inline-block",
          flex: "1 1 auto",
          overflow: "hidden",
          width: "100%",
          zIndex: 1,
        }}
      />
    </>
  );
}

export default Gallery;
